<template>
  <div
    class="`
      relative
      flex flex-row border flex-grow-0 flex-shrink-0 border-contrast
      justify-between
      rounded-lg cursor-pointer w-full mt-4 bg-background h-20
      text-content  py-2 px-4
      project-timeline-edit-component
    `"
  >
    <div
      @click="isToDo ? $emit('delete') : $emit('add')"
      @touchend="isToDo ? $emit('delete') : $emit('add')"
      class="add-remove-icon absolute flex justify-end w-7 h-7 -right-2 -top-3"
    >
      <delete-icon
        v-if="isToDo"
        class="text-red-500"
      />
      <plus-icon
        v-else
        class="text-green-500"
      />
    </div>
    <div class="flex flex-col justify-between">
      <p class="font-bold text-left text-primary">{{title}}</p>
      <p class="text-sm font-semibold text-left text-content">{{days}}</p>
    </div>

    <project-timeline-date
      v-if="date"
      :date="date"
      class="self-center"
    />

  </div>
</template>

<script>
import PlusIcon from 'vue-material-design-icons/PlusCircle.vue';
import DeleteIcon from 'vue-material-design-icons/CloseCircle.vue';
import ProjectTimelineDate from '@/components/projects/timeline/ProjectTimelineDate.vue';

export default {
  name: 'ProjectTimelineEditComponent',
  components: {
    PlusIcon,
    DeleteIcon,
    ProjectTimelineDate,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    duration: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: false,
      default: null,
    },
    isToDo: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    days() {
      if (this.duration === 1) {
        return `${this.duration} Day`;
      }
      return `${this.duration} Days`;
    },
  },
};
</script>
