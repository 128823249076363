<template>
  <interior-page-content-container
    nav-selection=""
    @back-click="backBtn"
    :show-bottom-nav="false"
    :show-back-button-top-left="true"
    :show-back-button-in-nav="true"
    :showRightControls="false"
    backButtonText="Project"
    :modal-show="modal.showDatePicker"
    :loading="loading"
  >
    <template #title>
      Edit Timeline
    </template>

    <template #modal>
      <assistance-booking-date-picker-modal
        v-if="modal.showDatePicker"
        @cancel="modal.showDatePicker = false"
        :date.sync="startDate"
        :busyDates="[]"
      />
    </template>

    <template #content>
      <div
        v-if="timeline"
        class="`
        flex flex-col items-center self-center h-full
        w-72 pt-4 md:w-80 lg:w-96 max-w-md
        flex-shrink-0
        pb-5
      `">
        <div class="w-full p-3 mb-4 rounded-lg bg-backfill">
          <button-edit-data
            @click="modal.showDatePicker = true"
            label="Start Date"
            :value="formatedDate"
            button-color="background"
            button-text="Edit"
          />
        </div>

        <p class="w-full text-xl font-extrabold text-left text-content">To Do</p>
        <draggable
          @change="updateList"
          v-model="timeline.to_do"
          group="items"
          class="w-full p-3 mb-4 rounded-lg bg-backfill to-do"
        >
          <project-timeline-edit-component
            v-for="(item, i) in toDoWithDate" :key="item.title"
            @delete="deleteItem(i)"
            :date="item.date"
            :title="item.title"
            :duration="item.duration"
            :isToDo="true"
          />
        </draggable>

        <p class="w-full mt-5 text-xl font-extrabold text-left text-content">To Add</p>
        <draggable
          group="items"
          v-model="timeline.to_add"
          class="w-full p-3 mb-4 rounded-lg bg-backfill to-add"
          @change="updateList"
        >
          <project-timeline-edit-component
            v-for="(item, i) in timeline.to_add" :key="item.title"
            @add="addItem(i)"
            @click="clickItem(item)"
            :title="item.title"
            :duration="item.duration"
            :isToDo="false"
          />
        </draggable>

        <div class="flex flex-row items-center justify-around flex-grow flex-shrink-0 w-full pb-4">

          <button-large
            @click="deleteTimeline"
            class="w-20 mt-5"
            color="red"
          >
            Delete
          </button-large>

          <button-large
            @click="backBtn"
            class="w-20 mt-5"
            color="green"
          >
            Save
          </button-large>

        </div>
      </div>

    </template>

  </interior-page-content-container>
</template>

<script>
/* eslint-disable no-param-reassign */

import Draggable from 'vuedraggable';
import moment from 'moment';

import { mapProjectFields } from '@/store/mappers';
import { PROJECT_GET, PROJECT_PUT } from '@/store/actions';

import InteriorPageContentContainer from '@/components/shared/containers/InteriorPageContentContainer/InteriorPageContentContainer.vue';
import ButtonLarge from '@/components/shared/Buttons/ButtonLarge.vue';
import ProjectTimelineEditComponent from '@/components/projects/timeline/ProjectTimelineEditComponent.vue';
import AssistanceBookingDatePickerModal from '@/components/assistance/AssistanceBookingDatePickerModal.vue';
import ButtonEditData from '@/components/shared/Buttons/ButtonEditData.vue';

export default {
  name: 'ProjectTimelineEditTimeline',
  components: {
    InteriorPageContentContainer,
    ButtonLarge,
    ProjectTimelineEditComponent,
    Draggable,
    AssistanceBookingDatePickerModal,
    ButtonEditData,
  },
  data() {
    return {
      loading: true,
      modal: {
        showDatePicker: false,
      },
    };
  },
  computed: {
    ...mapProjectFields({
      timeline: 'project.timeline',
    }),
    toDoWithDate() {
      if (!(this.timeline && this.timeline.to_do)) return [];
      const date = moment(this.timeline.start_date);

      return this.timeline.to_do.map((item) => {
        const result = {
          ...item,
          date: date.toString(),
        };
        date.add(item.duration, 'day');
        return result;
      });
    },
    startDate: {
      get() { return new Date(this.timeline.start_date); },
      set(val) {
        this.timeline.start_date = val.toISOString();
        this.updateList();
      },
    },
    formatedDate() {
      return moment(this.timeline.start_date).format('MMMM D, YYYY');
    },
  },
  created() {
    this.$store.dispatch(PROJECT_GET, this.$route.params.project_id)
      .catch(() => this.$router.push({
        name: 'SpecificPageNotFound',
        params: { page: 'project' },
      }))
      .finally(() => { this.loading = false; });
  },
  methods: {
    addItem(index) {
      this.timeline.to_do.push(this.timeline.to_add[index]);
      this.timeline.to_add = this.timeline.to_add.filter((item, i) => index !== i);
      this.updateList();
    },
    deleteItem(index) {
      this.timeline.to_add.push(this.timeline.to_do[index]);
      this.timeline.to_do = this.timeline.to_do.filter((item, i) => index !== i);
      this.updateList();
    },
    updateList() {
      this.$store.dispatch(PROJECT_PUT, {
        projectId: this.$route.params.project_id,
        project: {
          timeline: this.timeline,
        },
      });
    },
    clickItem(item) {
      this.$router.push({
        name: 'ProjectTimelineEditItem',
        params: {
          project_id: this.$route.params.project_id,
          item_id: item.id,
        },
      });
    },
    async deleteTimeline() {
      await this.$store.dispatch(PROJECT_PUT, {
        projectId: this.$route.params.project_id,
        project: {
          timeline: {},
        },
      });
      this.backBtn();
    },
    backBtn() {
      this.$router.push({
        name: 'ProjectTimeline',
        params: { project_id: this.$route.params.project_id },
      });
    },
  },
};
</script>

<style scoped>
.sortable-ghost {
  opacity: 0;
}
</style>
